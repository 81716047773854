.footer {
    margin-top:auto;
    background-color:black;
    padding:0 auto;
    color:white;
    display:flex;
    justify-content:center;
    padding:10px 0;
}

