

:root {
    --select-border: #777;
    --select-focus: blue;
    --select-arrow: var(--select-border);
  }
  
  .select {
    margin-right: 30px;
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
  
    min-width: 15ch;
    max-width: 30ch;
  
    border: 1px solid var(--select-border);
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    margin-top: 20px;

    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
  
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  }
  
  select:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid var(--select-focus);
    border-radius: inherit;
  }
  