html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.column {
  float: left;
  width: 33.3%;
  margin-bottom: 16px;
  padding: 0 8px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 8px;
}

.about-section {
  padding: 50px;
  text-align: center;
}

.container {
  padding: 0 16px;
}

.container::after, .row::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
}

.button {
  border: none;
  font-size: 15px;
  outline: 0;
  display: inline-block;
  padding: 0;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  width: fit-content;
  padding: 2px 5px;
}

.button:hover {
  background-color: #555;
}

@media screen and (max-width: 650px) {
  .column {
    width: 100%;
    display: block;
  }
}

.collegeInfo{
margin:20px 0px;
padding:15px;
width: 90%;
margin: 0 auto;
padding: 0 1%;
font-family: Arial;
text-align: justify;
color: black;
background-color: black;
border: 10px purple solid;
border-radius: 10px;
}

.photo{
float: left;
shape-outside: url("https://image-link.png");
shape-image-threshold: 0.9;
shape-margin: 2%;
width:200px;
height:200px;
}
.about{
background-color: WHite;
-webkit-user-select: none;
}
.my-border-left{
border-left:1px solid black;
}

.colm{
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: left;
text-align: left;
margin-bottom: 30px;
}
.colm p{
font-size:15px;
margin-bottom: 5px;
}
.fotcont{
text-align: left;
}
.fothead{
font-size:17px;
font-weight: bold;
}
.text{
width:750px;
max-width: 100%;
text-align: center;
margin:auto;
font-size:20px;
}
.display{
margin-top: 20px;
display: flex;
flex-direction: row;
justify-content: center;
}
.display p{
font-weight: bold;
}
.about-title{
text-decoration: underline;
margin:10px auto;
}
.about-title-2{
font-size: 25px;
text-decoration: underline;
margin:20px auto;
text-align: center;
}