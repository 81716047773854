@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
}
.app {
  background-color: #fafafa;
  min-height: 100vh;
}
.Icon {
  color: "white";
  padding-top: 3;
  font-size: 25px;
  margin-right: 10;
}
.app-header {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  top: 0;
  object-fit: contain;
  padding: 20px;
  background-color: white;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
}

.app-signin-form {
  object-fit: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-title {
  margin-bottom: 10px;
  font-size: large;
}

.app-login {
  background-color: lightblue;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
body {
  background: white;
  font-family: Georgia, serif;
  overflow-x: hidden;
  -webkit-user-select: none;
}
/* #18181e */
.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px;
}
.mainbar{
  display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    margin-left:20px;
}
.navbar-2{
  color:white;
}
.link{
  text-decoration: none;
  margin:auto;
}
.link:hover{
  text-decoration: none;
}
.linktext{
  text-decoration: none;
  color: white;
  font-size:25px;
  margin:auto 10px;
}
.linktext:hover{
  text-decoration: none;
  border-bottom: 4px solid rgb(148, 70, 144);
}
ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  list-style: none;
}
ul li Link {
  height: 60px;
  margin: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #707184;
  text-transform: capitalize;
  position: relative;
}
ul li Link:hover {
  color: #ececec;
  -webkit-transform: all 3s ease;
          transform: all 3s ease;
}
ul li Link:hover::after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  border: 1px solid #9466ff;
}
.active {
  color: #f3f3f3 !important;
}
.active::after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  border: 1px solid #9466ff;
}
.account {
  color: #fff;
  cursor: default;
  display: flex;
  margin-right: 20px;
  align-items: center;
}
.account .search {
  margin-right: 20px;
}
.account a {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}
.register {
  padding: 12px 19px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}
.login {
  padding: 12px 23px;
  background: #9466ff;
  border: none;
  color: #fff;
  border-radius: 4px;
}
nav {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-5 {
  width: 100%;
  background-color: #121214;
  height: 78px;
  border-bottom: 1px solid #242424;
}
.nav-5 nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.search-input::-webkit-input-placeholder{
  color:black;
}
.signup-btn{
  color: aliceblue !important;
  margin-left: 10px !important;
}
.btn {
  background: transparent;
  color: #fff;
}

.movie-title {
  overflow-y: scroll;
  white-space: nowrap;
}
.runtime{
  font-size:0.5em;
}

.movie-title::-webkit-scrollbar {
  display: none;
}

.movie-title:hover {
  overflow: visible;
  white-space: normal;
  overflow-y: hidden;
}

.trailer {
  width: auto;
  display: flex;
  justify-content: center;
}

.titleBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.title {
  font-size: 2em;
  text-align: justify;
}
.titleDetails {
  display: flex;
  flex-direction: column;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 3%;
}
.details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 20px;
}
.detail {
  margin: 5px 10px;
}
.rattings {
  display: flex;
  flex-direction: row;
  margin: 0 3%;
  float: right;
}
.ratting {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  justify-content: center;
}
.rate {
  font-size: 25px;
  text-align: center;
}
.value {
  font-size: 18px;
  text-align: center;
}
.DetailsTables {
  display: flex;
  flex-flow: row wrap;
}
.detailsName {
  display: flex;
  flex-direction: column;
  margin: 0 30px;
}

.table {
  border: 1px #a39485 solid;
  font-size: 0.9em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
}
.rwd-table-width {
  margin: 1em auto !important;
  width: 26%;
}
.rwd-table-width-2 {
  margin: 1em 30px !important;
  width: -webkit-fill-available;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.rwd-table th {
  display: none;
}
.rwd-table td {
  display: block;
}
.rwd-table td:first-child {
  padding-top: 0.5em;
}
.rwd-table td:last-child {
  padding-bottom: 0.5em;
}
.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 6.5em;
  display: inline-block;
}
@media (min-width: 480px) {
  .rwd-table td:before {
    display: none;
  }
}
.rwd-table th,
.rwd-table td {
  text-align: left;
}
@media (min-width: 480px) {
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: 0.25em 0.5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
}

h1 {
  font-weight: normal;
  letter-spacing: -1px;
  color: #34495e;
}

.rwd-table {
  background: #34495e;
  color: #fff;
  border-radius: 0.4em;
  overflow: hidden;
  margin: 20px auto;
  padding: 1%;
  min-width: 300px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rwd-table tr {
  border-color: #46637f;
}
.rwd-table th,
.rwd-table td {
  margin: 0.5em 1em;
}
@media (min-width: 480px) {
  .rwd-table th,
  .rwd-table td {
    padding: 5px 10px !important;
  }
}
.rwd-table th,
.rwd-table td:before {
  color: #dd5;
  font-size: large;
}

.genre {
  margin-left: -5px;
}

.genre span {
  display: inline-block;
  padding: 0 7px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border: 1.7px solid;
  border-radius: 2em;
  margin: 4px;
}

@media (max-width: 1024px) {
  body .flex .item {
    width: 50%;
  }
}
@media (max-width: 768px) {
  body .flex .item {
    width: 100%;
  }
}

.item {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.modal-head {
  font-size: 25px;
  margin-bottom: 9px;
  margin-top: 3px;
}

.modal-ratting {
  margin-top: 5px;
  margin-left: 50%;
  margin-right: 50%;
}
.modal-input {
  margin-bottom: 10px;
  margin-top: 3px;
}
.modal-button {
  margin-top: 10px;
  margin-bottom: 5px;
}

/* profile table */
.tableT{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border: none;
  margin: 0 20px;
  font-size: 0.9em;
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  font-size: 20px; */
}
.td{
  font-size:20px;
}
.tr{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.tr:hover{
  background-color:rgba(211, 211, 211, 0.349);
}
.content{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.label{
  font-weight: bolder;
}
.form-group{
  margin-bottom:10px;
}
.ratebutton{
  position: relative;
}
.ratebtn{
  vertical-align: center;
  margin: auto;
}

.moviecard{
  display:flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.account-settings .user-profile {
  margin: 0 0 1rem 0;
  padding-bottom: 1rem;
  text-align: center;
}
.account-settings .user-profile .user-avatar {
  margin: 0 0 1rem 0;
}
.account-settings .user-profile .user-avatar img {
  width: 90px;
  height: 90px;
  border-radius: 100px;
}
.account-settings .user-profile h5.user-name {
  margin: 0 0 0.5rem 0;
}
.account-settings .user-profile h6.user-email {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 400;
  color: #9fa8b9;
}
.account-settings .about {
  margin: 2rem 0 0 0;
  text-align: center;
}
.account-settings .about h5 {
  margin: 0 0 15px 0;
  color: #007ae1;
}
.account-settings .about p {
  font-size: 0.825rem;
}
.form-control {
  border: none;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  border-radius: 2px;
  font-size: .825rem;
  background: #ffffff;
  color: #2e323c;
}
.padding{
  padding:20px;
  padding-right:0;
}
.pading{
  padding:20px;
}
.card {
  background: #ffffff;
  border-radius: 5px;
  border: 0;
  margin-bottom: 1rem;
}
.main-profile{
  margin : auto;
}

.manuItem{
  margin:0 10px;
}

.app-main-div{
  display: flex;
  flex-direction:column;
  min-height: 100vh;
}

.authbtn{
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content;
}

.active::after{
  border:none!important;
}

/* Ifame style */

.iframe-block {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

@media screen and (min-width:600px){
  .iframe-block {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
  
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 10%;
    bottom: 0;
    right: 0;
    width: 80%;
    height: 80%;
    border: none;
  }
}




:root {
    --select-border: #777;
    --select-focus: blue;
    --select-arrow: var(--select-border);
  }
  
  .select {
    margin-right: 30px;
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
  
    min-width: 15ch;
    max-width: 30ch;
  
    border: 1px solid #777;
  
    border: 1px solid var(--select-border);
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    margin-top: 20px;

    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
  
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  }
  
  select:focus + .focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid blue;
    border: 2px solid var(--select-focus);
    border-radius: inherit;
  }
  
.account-settings .user-profile {
    margin: 0 0 1rem 0;
    padding-bottom: 1rem;
    text-align: center;
}
.account-settings .user-profile .user-avatar {
    margin: 0 0 1rem 0;
}
.account-settings .user-profile .user-avatar img {
    width: 90px;
    height: 90px;
    border-radius: 100px;
}
.account-settings .user-profile h5.user-name {
    margin: 0 0 0.5rem 0;
}
.account-settings .user-profile h6.user-email {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 400;
    color: #9fa8b9;
}
.account-settings .about {
    margin: 2rem 0 0 0;
    text-align: center;
}
.account-settings .about h5 {
    margin: 0 0 15px 0;
    color: #007ae1;
}
.account-settings .about p {
    font-size: 0.825rem;
}
.form-control {
    border: 1px solid #cfd1d8;
    border-radius: 2px;
    font-size: .825rem;
    background: #ffffff;
    color: #2e323c;
}

.card {
    background: #ffffff;
    border-radius: 5px;
    border: 0;
    margin-bottom: 1rem;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.column {
  float: left;
  width: 33.3%;
  margin-bottom: 16px;
  padding: 0 8px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 8px;
}

.about-section {
  padding: 50px;
  text-align: center;
}

.container {
  padding: 0 16px;
}

.container::after, .row::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
}

.button {
  border: none;
  font-size: 15px;
  outline: 0;
  display: inline-block;
  padding: 0;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 5px;
}

.button:hover {
  background-color: #555;
}

@media screen and (max-width: 650px) {
  .column {
    width: 100%;
    display: block;
  }
}

.collegeInfo{
margin:20px 0px;
padding:15px;
width: 90%;
margin: 0 auto;
padding: 0 1%;
font-family: Arial;
text-align: justify;
color: black;
background-color: black;
border: 10px purple solid;
border-radius: 10px;
}

.photo{
float: left;
shape-outside: url("https://image-link.png");
shape-image-threshold: 0.9;
shape-margin: 2%;
width:200px;
height:200px;
}
.about{
background-color: WHite;
-webkit-user-select: none;
}
.my-border-left{
border-left:1px solid black;
}

.colm{
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: left;
text-align: left;
margin-bottom: 30px;
}
.colm p{
font-size:15px;
margin-bottom: 5px;
}
.fotcont{
text-align: left;
}
.fothead{
font-size:17px;
font-weight: bold;
}
.text{
width:750px;
max-width: 100%;
text-align: center;
margin:auto;
font-size:20px;
}
.display{
margin-top: 20px;
display: flex;
flex-direction: row;
justify-content: center;
}
.display p{
font-weight: bold;
}
.about-title{
text-decoration: underline;
margin:10px auto;
}
.about-title-2{
font-size: 25px;
text-decoration: underline;
margin:20px auto;
text-align: center;
}
.footer {
    margin-top:auto;
    background-color:black;
    padding:0 auto;
    color:white;
    display:flex;
    justify-content:center;
    padding:10px 0;
}


